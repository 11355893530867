<template>
  	<div class="tree">
	    <node :node="treeData" :fndate="fdate" :tndate="tdate" root="0" type="heads"></node>
        <div class="cl"></div>
        <div class="dl"></div>
        <div class="rl"></div>
  	</div>
</template>
<style lang="scss" scoped>
    .tree {
        position: relative;
        overflow: hidden;
        padding-left: 10px;
        .cl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 99px;
            top: 0;
        }
        .dl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 200px;
            top: 0;
        }
        .rl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 253px;
            top: 0;
        }
    }
</style>

<script>
import Node from "./Node";

export default {
	props: {
		treeData: null,
		fdate: null,
		tdate: null
	},

	methods: {
		handleClick (node) {
			//this.$emit('node-click', node);
		}
	},
	
	components: {
		Node
	}
};
</script>