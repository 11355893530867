<template>
    <div :class="`list-item ${type}`">
        <div :class="getClass(node)" @click="toggleChildren">
            <strong class="root label" v-if="root == 0">{{ node.name }}</strong>
            <strong class="label" v-else-if="hasChildren(node)">{{ node.name }}</strong>
            <span class="label" @click="ledgerFunction(node)" v-else>{{ node.name }}</span>

            <div class="ref">&nbsp;</div>

            <div class="odebit">
                <b v-if="getOpening(node) > 0">
                    <span v-if="hasChildren(node) && !showChildren">{{ getOpening(node) | fixAccount }}</span>
                    <span v-else-if="hasChildren(node) && showChildren">&nbsp;</span>
                    <span v-else>{{ getOpening(node) | fixAccount }}</span>
                </b>
                <b v-else>&nbsp;</b>
            </div>
            <div class="ocredit">
                <b v-if="getOpening(node) < 0">
                    <span v-if="hasChildren(node) && !showChildren">{{ getOpening(node) | fixAccount }}</span>
                    <span v-else-if="hasChildren(node) && showChildren">&nbsp;</span>
                    <span v-else>{{getOpening(node) | fixAccount }}</span>
                </b>
                <b v-else>&nbsp;</b>
            </div>
            <div class="tdebit">
                <b v-if="node.sum_dr > 0">
                    <span v-if="hasChildren(node) && !showChildren">{{ node.sum_dr | fixAccount}}</span>
                    <span v-else-if="hasChildren(node) && showChildren"></span>
                    <span v-else>{{ node.sum_dr | fixAccount }}</span>
                </b>
                <b v-else>&nbsp;</b>
            </div>
            <div class="tcredit">
                <b v-if="node.sum_cr > 0">
                    <span v-if="hasChildren(node) && !showChildren">{{node.sum_cr | fixAccount }}</span>
                    <span v-else-if="hasChildren(node) && showChildren">&nbsp;</span>
                    <span v-else>{{node.sum_cr | fixAccount }}</span>
                </b>
                <b v-else>&nbsp;</b>
            </div>
            <div class="cdebit">
                <b v-if="getClosing(node) > 0">
                    <span v-if="hasChildren(node) && !showChildren">{{ getClosing(node) | fixAccount }}</span>
                    <span v-else-if="hasChildren(node) && showChildren">&nbsp;</span>
                    <span v-else>{{ getClosing(node) | fixAccount }}</span>
                </b>
                <b v-else>&nbsp;</b>
            </div>
            <div class="ccredit">
                <b v-if="getClosing(node) < 0">
                    <span v-if="hasChildren(node) && !showChildren">{{ getClosing(node) | fixAccount }}</span>
                    <span v-else-if="hasChildren(node) && showChildren">&nbsp;</span>
                    <span v-else>{{getClosing(node) | fixAccount }}</span>
                </b>
                <b v-else>&nbsp;</b>
            </div>
        </div>
        <transition name="list">
            <div style="padding-left: 50px;" v-if="showChildren && hasChildren(node)">
                <template v-if="node.ledger_heads && node.ledger_heads.length">
                    <node v-for="(child) in node.ledger_heads" :node="child" :root="parseInt(root) + 1" type="items"
                          :key="`${child.id}-${root}`"></node>
                </template>
                <template v-if="node.deep_down && node.deep_down.length">
                    <node v-for="(child) in node.deep_down" :node="child" :root="parseInt(root) + 1" type="heads"
                          :key="child.id"></node>
                </template>
            </div>
        </transition>
    </div>
    <!-- <div class="list-item">
        <div :class="hasChildren(node) ? 'has-children' : ''" @click="toggleChildren">
            <strong style="width: 50px; display: inline-block"></strong>
            <strong v-if="root == 'root'" class="root" style="text-decoration-style: double;text-decoration-line: underline;">{{ node.name }}</strong>
            <strong v-else-if="hasChildren(node)"><u>{{ node.name }}</u></strong>
            <span v-else style="display: inline-block; margin-bottom: 5px;">{{ node.name }}</span>
            <strong style="float: right; padding: 0 5px;" :class="node.balance < 0 ? 'margined' : 'normal'" v-if="!hasChildren(node)">{{ node.balance | fixAccount }}</strong>
            <strong style="float: right; padding: 0 5px;" :class="node.balance < 0 ? 'margined' : 'normal'" v-else-if="!showChildren">{{ node.balance | fixAccount }}</strong>
            <strong v-if="!node.balance" class="normal"></strong>
        </div>
        <transition name="list">
            <div style="padding-left: 50px;" v-if="showChildren && hasChildren(node)">
                <ul v-if="node.ledger_heads && node.ledger_heads.length">
                    <node v-for="(child, key) in node.ledger_heads" :node="child" :root="key" :key="child.id"></node>
                </ul>
                <ul v-if="node.deep_down && node.deep_down.length">
                    <node v-for="(child, key) in node.deep_down" :node="child" :root="key" :key="child.id"></node>
                </ul>
            </div>
        </transition>
    </div> -->
</template>
<style lang="scss" scoped>
    .list-item {
        div {
            .label {
                display: inline-block;
                width: calc(100% - 633px);
                text-transform: capitalize;
                float: left;
            }
            strong {
                text-decoration: underline;
                &.root {
                    text-transform: uppercase;
                    text-decoration-style: double;
                    text-decoration-line: underline;
                }
            }

            .ref {
                float: left;
                width: 51px;
                padding: 0px 10px;
                text-align: center;
            }

            .odebit {
                float: left;
                width: 97px;
                padding: 0px 5px;
                text-align: right;
            }

            .ocredit {
                float: left;
                width: 97px;
                padding: 0px 5px;
                text-align: right;
            }

            .tdebit {
                float: left;
                width: 97px;
                padding: 0px 5px;
                text-align: right;
            }

            .tcredit {
                float: left;
                width: 97px;
                padding: 0px 5px;
                text-align: right;
            }

            .cdebit {
                float: left;
                width: 97px;
                padding: 0px 5px;
                text-align: right;
            }

            .ccredit {
                float: left;
                width: 97px;
                padding: 0px 5px;
                text-align: right;
            }
        }
    }
</style>
<script>
    import {mapState} from 'vuex';

    export default {
        name: "node",
        props: {
            node: null,
            type: null,
            root: null,
        },
        data: () => ({
            showChildren: true
        }),
        computed: {
            ...mapState(['trialbalance']),
        },
        methods: {
            toggleChildren() {
                this.showChildren = !this.showChildren
            },
            getClass(node) {
                if (this.hasChildren(node)) {
                    return `has-children level${this.root}`
                } else return `level${this.root}`
            },
            getOpening(node) {
                //return node.balance + node.sum_cr - node.sum_dr
                return node.balance
            },
            getClosing(node) {
                return node.balance - node.sum_cr + node.sum_dr
                // return node.balance
            },
            ledgerFunction(node) {
                this.$router.push({
                    name: 'account-statement',
                    query:
                        {
                            id: node.id,
                            name: node.name,
                            frmDate: this.trialbalance.filter_date.from_date,
                            toDate: this.trialbalance.filter_date.to_date
                        }
                })
            },
            hasChildren: (node) => (node.deep_down && node.deep_down.length) || (node.ledger_heads && node.ledger_heads.length)
        },
        filters: {
            fixAccount(item) {
                if (!isNaN(item)) return item >= 0 ? item.numberFormat() : `${(-(item)).numberFormat()}`
            }
        }
    };
</script>