<template>
    <div class="tree">
        <node-with-detail :node="treeData" root="0" type="heads"></node-with-detail>
        <div class="ccl"></div>
        <div class="cdl"></div>
        <div class="tcl"></div>
        <div class="tdl"></div>
        <div class="ocl"></div>
        <div class="odl"></div>
        <div class="rl"></div>
    </div>
</template>
<style lang="scss" scoped>
    .tree {
        position: relative;
        overflow: hidden;
        padding-left: 10px;
        .ccl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 97px;
            top: 0;
        }
        .cdl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 193px;
            top: 0;
        }
        .tcl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 290px;
            top: 0;
        }
        .tdl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 387px;
            top: 0;
        }
        .ocl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 484px;
            top: 0;
        }
        .odl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 581px;
            top: 0;
        }
        .rl {
            position: absolute;
            width: 1px;
            height: 100%;
            background: black;
            right: 633px;
            top: 0;
        }
    }
</style>

<script>
import NodeWithDetail from "./NodeWithDetail";

export default {
	props: {
		treeData: null,
        type: null
	},

	methods: {
		handleClick (node) {
			this.$emit('node-click', node);
		}
	},
	
	components: {
		NodeWithDetail
	},
    filters: {
        fixAccount(item) {
            if(!isNaN(item)) return item >= 0 ? item.numberFormat() : `${(-(item)).numberFormat()}`
        }
    }
};
</script>